<template>
  <o-modal
      :active="isActive"
      :canCancel="false"
      class="modal-md"
    >
    <div class="modal-card create-assembly-part">
      <!-- Modal Header -->
      <header class="modal-card-head">
        <h4 class="modal-card-title" >
           Create Parts
        </h4>
        <!-- <div class="is-divider-vertical"></div> -->
        <!-- <i class="icon-close is-clickable" @click="$emit('close')"></i> -->
      </header>

      <!-- Modal Body -->
      <div class="modal-card-body has-background-white p-0">

          <!-- Part details -->
          <div class="column pt-0">
              <h4 class="is-size-3">
                Part Details*
              </h4>

              <div class="columns">
                <div class="column pt-0">

                    <!-- ID -->
                    <div class="field">
                      <div class="field-label">
                        <label class="label is-size-3"> Part ID</label>
                      </div>
                      <div class="field-body">
                        <div class="control">
                            <input class="input"
                              v-model.trim="part.partId"
                              placeholder="Enter ID">
                          </div>
                      </div>
                    </div>

                    <!-- Name -->
                    <div class="field">
                      <div class="field-label">
                        <label class="label is-size-3"> Name *</label>
                      </div>
                      <div class="field-body">
                        <div class="control">
                            <input class="input"
                              v-model="part.partName"
                              type="text"
                              :min="3"
                              placeholder="Enter Name">
                          </div>
                      </div>
                    </div>

                    <!-- Description -->
                    <div class="field">
                      <div class="field-label">
                        <label class="label is-size-3"> Description </label>
                      </div>
                      <div class="field-body">
                        <div class="control  is-clearfix">
                          <textarea class="textarea has-background-white"
                              placeholder="Enter a description"
                              rows="3"
                              v-model.trim="part.partDesc"></textarea>
                        </div>
                      </div>
                    </div>

                </div>
                <div class="column pt-0">

                    <!-- Measure Units -->
                  <div class="field">
                      <label class="label">Measure Units</label>
                      <div class="field-body overflow-visible has-background-white">
                        <mf-multi-select v-model="part.measureUnits"
                                        placeholder="Select a measured unit"
                                        :options="measureUnits"
                                        openDirection="below"
                                        :clear-on-select="false">
                        </mf-multi-select>
                      </div>
                  </div>

                  <!-- Cat Id -->
                  <div class="field">
                      <div class="field-label">
                        <label class="label"> Catalog ID </label>
                      </div>
                      <div class="field-body">
                        <div class="control">
                            <input class="input"
                            @blur="catIdValidate($event)"
                              v-model="part.partCatID"
                              placeholder="Enter Catalog ID">
                          </div>
                      </div>
                  </div>

                  <!-- Category -->
                  <div class="field">
                        <label class="label"> Category </label>
                        <div class="control">
                          <mf-multi-select
                            :modelValue="!isEmpty(part.selectedCategories) ? part.selectedCategories : []"
                            v-model="part.selectedCategories"
                            placeholder="Select Category"
                            label="name"
                            :searchable="false"
                            :options="categories?.data"
                            openDirection="below"
                            :clear-on-select="false"
                            track-by="_id"
                            @update:modelValue="fetchSubCategories()"
                          ></mf-multi-select>
                        </div>
                  </div>

                  <!-- Sub Category -->
                  <div class="field">
                      <div class="field-label">
                        <label class="label is-size-3"> Sub Category </label>
                      </div>
                      <div class="field-body">
                        <div class="control">
                              <mf-multi-select
                                :modelValue="!isEmpty(part.selectedSubcategory) ? part.selectedSubcategory : []"
                                v-model="part.selectedSubcategory"
                                label="name"
                                placeholder="Select Subcategory"
                                :searchable="false"
                                :allow-empty="false"
                                :options="subCategories"
                                openDirection="below"
                                track-by="_id"
                                @update:modelValue="emitRefreshParams"
                              ></mf-multi-select>
                          </div>
                      </div>
                  </div>

                </div>
              </div> <!-- end of columns -->
          </div> <!-- end of column -->

          <!-- Vendors Select -->
          <div class="column pt-0">
              <h4 class="modal-card-title" >
                Select Vendors
              </h4>
              <!-- search bar -->
              <search-bar
                placeholder="Search"
                ref="searchComp"
                :shouldEmit="true"
                @search="getSearchValue" class="mr-5"
              ></search-bar>

              <!-- vendors table -->
              <div class="table-container create-assembly-part">
                <mf-table
                  ref="vendorsTable"
                  :key="refreshKey"
                  :tableProps="tableProps"
                  :loadData="loadData"
                  :tab="{id: 'parts'}"
                  :checkedRows="selectedVendors"
                  @checkbox-toggled="updateCheckedRows"
                >
                  <template v-slot:prefVendor="{ rowData: vendor }">
                      <o-checkbox v-model="vendor.isPreferred">
                      </o-checkbox>
                    </template>
                    <template v-slot:code="{ rowData: vendor }">
                      <field-generic-input
                        :value="vendor.code"
                        :maxlength="32"
                        :isEditing="true"
                        @update:value="(val) => set(vendor, 'code', val)"
                        >
                      </field-generic-input>
                    </template>
                    <template v-slot:defVendor="{ rowData: vendor }">
                      <o-checkbox v-model="vendor.isDefault"
                      @update:modelValue="setDefaultVendor($event, vendor)"
                        >
                      </o-checkbox>
                    </template>
                    <template v-slot:desc="{ rowData: vendor }">
                        <field-generic-input
                          :value="vendor.desc"
                          :maxlength="32"
                          :isEditing="true"
                          @update:value="(val) => set(vendor, 'desc', val)"
                          >
                      </field-generic-input>
                    </template>
                    <template v-slot:unitCost="{ rowData: vendor }">
                      <field-generic-input
                          :value="vendor.unitCost || 0"
                          :isEditing="true"
                          type="number"
                          :min="0"
                          :max="9999"
                          :step="1"
                          fieldClass="has-text-right"
                          @update:value="(val) => set(vendor, 'unitCost', val)"
                        >
                      </field-generic-input>
                    </template>
                    <template v-slot:leadTime="{ rowData: vendor }">
                        <field-generic-input
                          :value="vendor.leadTime || 0"
                          :isEditing="true"
                          type="number"
                          :min="0"
                          :max="9999"
                          :step="1"
                          fieldClass="has-text-right"
                          @update:value="(val) => set(vendor, 'leadTime', val)"
                        >
                      </field-generic-input>
                    </template>
                </mf-table>
              </div>
            </div>

      </div> <!-- END OF MODAL CARD BODY -->

      <!-- Modal Footer -->
      <footer class="modal-card-foot is-justify-content-flex-end is-align-items-center">
          <div class="column is-narrow ">
            <div>
              <button class="button is-outlined" @click="$emit('close', {cancel: true})">
                Cancel
              </button>
               <button class="button has-background-black-bis" :disabled="disableCreate" @click="createPart">
                Create
              </button>
            </div>
          </div>
      </footer>
      <o-loading
        :full-page="true"
        :active="isLoading"
        :can-cancel="true"
      ></o-loading>
    </div>
  </o-modal>
</template>
<script>
import {
  reactive, toRefs, onMounted, defineAsyncComponent, ref, computed,
} from 'vue';
import { useStore } from 'vuex';
import Vendors from '@/models/Vendors';
import Catalogs from '@/models/Catalogs';
import Validation from '@/components/utils/Validations';
import {
  get, isEmpty, every, filter, forEach, trim, map, first, set,
} from 'lodash';
// import tableDefinition from '@/components/table-cols/partsAddCols';
import tableDefinition from '@/components/table-cols/vendorAddCols';
import { useToast } from 'vue-toastification';
import SearchBar from '@/components/SearchBar.vue';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default {
  name: 'CreateParts',
  props: ['isActive'],
  components: {
    MfTable,
    SearchBar,
  },
  setup(props, { emit }) {
    const store = useStore();
    const toast = useToast();
    const partsTable = ref(null);
    const state = reactive({
      isLoading: false,
      part: {
        partId: '',
        partName: '',
        partDesc: '',
        partCatID: '',
        measureUnits: '',
        selectedCategories: {},
        selectedSubcategory: {},
      },
      allVendors: [],
      categories: [],
      subCategories: [],
      measureUnits: [],
      refreshKey: 1,
      selectedVendors: [],
      searchToken: '',
    });
    const tableProps = tableDefinition;

    const validationChecks = (part) => {
      let errMsg = null;
      if (isEmpty(part.name) || part.name.trim().length < 3) {
        errMsg = 'Part Name should be atleast 3 characters long!';
      } else if (!isEmpty(part.catId) && !Validation.validateCatalogId(part.catId)) {
        errMsg = "part's catId is mandatory and should be 4 to 32 character long";
      } else if (!Validation.isValidFloat(part, 'quantity')) {
        if (!trim(part.quantity)) {
          part.quantity = 1;
        } else {
          errMsg = 'part Quantity must be integer and should be in range between 0.01 and 9999';
        }
      }
      if (errMsg) {
        toast.error(errMsg);
        return false;
      }
      return true;
    };
    
    const catIdValidate = (event) => {
      const partCatID = event.target.value;
      if (!isEmpty(partCatID)) {
        state.part.partCatID = Validation.getValidCatId(partCatID);
        if (partCatID.toUpperCase() !== state.part.partCatID) {
          toast.success('Updated Catalog Id by removing unsupported characters');
        }
      }
    };

    const validateCostCode = (assemblyParts) => {
      if (!isEmpty(assemblyParts.costCode)) {
        forEach(assemblyParts.costCode, (costCode) => {
          if (!isEmpty(costCode.code)) {
            costCode.code = costCode.code.trim();
            costCode.code = costCode.code.replace(/ /g, '.');
          }
        });
      }
    };

    const createPart = async () => {
      let totalPrefVendor = 0;
      if (state.selectedVendors.length) {
        state.selectedVendors.forEach((vendor) => {
          vendor.leadTime = parseInt(vendor.leadTime, 10);
          vendor.newRow = true;
          totalPrefVendor += (vendor.isPreferred) ? 1 : 0;
        });
      }
      if (totalPrefVendor > 3) {
        toast.error('More than Three Preferred Vendor selected. Please select maximum 3 as Preferred');
        return;
      }
      const newPart = {
        catId: state.part.partCatID,
        category: state.part.selectedCategories,
        costCode: [],
        customId: state.part.partId,
        desc: state.part.partDesc,
        isEditing: true,
        labour: [],
        measureUnits: state.part.measureUnits,
        name: state.part.partName,
        newRow: true,
        subCategory: isEmpty(state.part.selectedSubcategory) ? state.subCategories[0] : state.part.selectedSubcategory,
        vendor: state.selectedVendors,
      };

      // validations for part
      if (get(newPart, 'vendor.length', 0) > 0
        && (every(newPart.vendor, (dt) => dt.isDefault === false || dt.isDefault === ''))) {
        toast.warning('No default Vendor Checked So setting the new vendor as Default');
        newPart.vendor[0].isDefault = true;
      }
      filter(newPart.vendor, (val) => {
        if (val.leadTime < 0) {
          toast.warning('Lead time can not be less than 0');
        }
      });

      newPart.vendor = filter(newPart.vendor, (v) => v.isPreferred || v.isDefault || v.code
        || v.isManual || v.desc || v.isImported || v.unitCost || v.leadTime || v.newRow);

      if (newPart.vendor.length > 25) {
        toast.warning('Maximum of only 25 vendors allowed to be associated with a part!');
        return;
      }

      state.isLoading = true;
      try {
        const type = 'items';
        // validate cost code
        validateCostCode(newPart);

        if (!isEmpty(newPart.catId)) {
          newPart.catId = Validation.getValidCatId(newPart.catId);
        }
        let savedParts;
        if (validationChecks(newPart)) {
          savedParts = await Catalogs.addAssemblyParts([newPart], type);

          if (!isEmpty(savedParts.failedData)) {
            const errMessage = get(savedParts.failedData[0], '_errorMsg', 'Please Contact ManufactOn Support');
            toast.error(errMessage);
          }

          if (!isEmpty(savedParts.data)) {
            toast.success('New Part added');
            emit('close');
          }
        }
      } catch (err) {
        console.log('err', err);
        toast.error(`${err.data.message || err.data.msg || 'There was some error creating assembly'}`);
      }
      state.isLoading = false;
    };

    const setDefaultVendor = (isChecked, vendor) => {
      // toggle default vendor checkbox
      if (state.allVendors.length) {
        for (let i = 0; i < state.allVendors.length; i++) {
          if (state.allVendors[i]._id === vendor._id && isChecked) {
            state.allVendors[i].isDefault = true;
          } else {
            state.allVendors[i].isDefault = false;
          }
        }
      }
    };

    const loadData = async () => {
      // get all vendors
      const { data: vendors } = await Vendors.getVendors({
        categoryId: get(state.part.selectedCategories, '_id', ''),
        subCategoryId: state.part.selectedSubcategory?._id,
        sort: { sortField: 'name', direction: 'asc' },
        type: 'Parts',
        search: state.searchToken,
        limit: 9999,
        page: 1,
      });
      state.allVendors = vendors;
      return { data: state.allVendors };
    };

    const emitRefreshParams = () => {
      emit('re-render-table', { categories: state.part.selectedCategories, subCategories: state.part.selectedSubcategory });
      state.refreshKey++;
    };

    const fetchSubCategories = async () => {
      if (!isEmpty(state.part.selectedCategories)) {
        const categoryId = get(state.part.selectedCategories, '_id', '');
        if (!isEmpty(categoryId)) {
          state.isLoading = true;
          state.subCategories = await Vendors.getSubCategories({ categoryId });
          state.part.selectedSubcategory = first(state?.subCategories) || [];
          state.isLoading = false;
          emitRefreshParams();
          return;
        }
      }
      state.subCategories = [];
      state.part.selectedSubcategory = {};
      emitRefreshParams();
    };

    const updateCheckedRows = (rows) => {
      state.selectedVendors = rows;
    };

    const getSearchValue = (searchValue) => {
      state.searchToken = searchValue;
      emitRefreshParams();
    };

    onMounted(async () => {
      // initialze all categories
      state.isLoading = true;
      state.categories = await Vendors.getCategories({ limit: 500 });
      const { companyData } = store.state;
      state.measureUnits = map(companyData.measureUnits, (unit) => unit.name);
      state.isLoading = false;
    });

    const disableCreate = computed(() => {
      if (isEmpty(state.part.partName) || state.part.partName.length <= 2) {
        return true;
      }
      return false;
    });

    return {
      ...toRefs(state),
      createPart,
      fetchSubCategories,
      emitRefreshParams,
      tableProps,
      loadData,
      updateCheckedRows,
      partsTable,
      getSearchValue,
      isEmpty,
      setDefaultVendor,
      set,
      disableCreate,
      catIdValidate
    };
  },
};
</script>
<style scoped>
.mt-4 {
  margin-top: 16px !important;
}
</style>
