<template>
  <div :class="labelPosition === 'is-left' ? 'field is-horizontal' : 'field'">
    <label class="has-text-black-bis is-italic is-size-3" v-if="label"
      :class="labelPosition === 'is-left' ? 'line-height' : 'label'">
      {{ label }}
    </label>
    <div v-if="isStatus" class="stage-color is-flex is-align-items-center p-0 status-col">
      <div v-if="isStaticColor || ['Unscheduled','UnderDelivered',
        'Short Delivery'].includes(displayValue)" class="image is-16x16 status-circle"
        :class="getStaticColor(displayValue)"></div>
      <div v-else v-stage-color="colorScheme[colorStatus]" class="image is-16x16 status-circle">
      </div>
      <span class="line-height">{{ ['Qc inspect', 'Qc fail'].includes(displayValue) ?
        getQcStatusVal(displayValue) : $_.startCase(displayValue) }} </span>
    </div>
    <div v-else-if="isItemNotPurchased()">

    </div>
    <div v-else class="stage-color" v-stage-color="colorScheme[colorStatus]">
      {{ displayValue }}
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  every, camelCase, capitalize, isFunction, has,
} from 'lodash';

export default defineComponent({
  name: 'StageField',
  props: [
    'rowData',
    'label',
    'value',
    'labelPosition',
    'stage',
    'module',
    'cardType',
    'isStatus',
    'shipType',
    'shippingItems',
    'isStaticColor',
    'isPurchased',
  ],
  setup(props) {
    const route = useRoute();
    const store = useStore();
    const colorScheme = computed(() => store.state.colorScheme);

    const colorStatus = computed(() => {
      const tmp = camelCase(props.value);
      if (tmp === 'inventory') return 'releasedToInventory';
      if (['mixedShipping', 'mixed'].includes(tmp)) return 'mixColor';
      if (tmp === 'planning') return 'prefab';
      if (tmp === 'qa' && props.cardType === 'Materials') return 'fieldverify';
      if (tmp === 'scheduled') return 'notStarted';
      if (tmp === 'requesting') return 'preparation';
      if(tmp === 'shortDelivery') return 'inStorage';
      if (tmp === 'preparation') {
        if (
          ['sourcing', 'ordering'].includes(route.params.stage)
          && route.params.cardId === 'add'
        ) return route.params.stage;
        return 'preparation';
      }
      return tmp;
    });

    const isItemNotPurchased = () => {
      if (has(props.rowData, 'purchase') && props.rowData && props?.isPurchased && isFunction(props.isPurchased)) {
        return !props.isPurchased(props.rowData);
      }
      return false;
    };
    const displayValue = computed(() => {
      if (!props.value) return null;
      if (props.value === 'pause') return 'Paused';
      if (props.value === 'rejected') return (isItemNotPurchased()) ? '' : 'Rejected';
      if (props.module === 'shipping') return 'Shipping';
      if (props.value === 'unscheduled') return 'Unscheduled';
      if (props.value === 'underDelivered') return 'UnderDelivered';
      if (props.value === 'submitted') return 'Submitted';
      if (props.value === 'fulfilled') return 'Complete';
      if (props.value == "released-to-inventory") return 'Inventory';

      if (props.value === 'preparation') {
        if (
          ['sourcing', 'ordering'].includes(route.params.stage)
          && route.params.cardId === 'add'
        ) return capitalize(route.params.stage);
        if (isItemNotPurchased()) {
          return '';
        }
        return 'Requesting';
      }
      if (
        props.value === 'qa'
        && (props.cardType === 'Materials')
      ) return 'Field Measurement';
      if (props.isStatus && props.value === 'noWorkflow') {
        return 'No Workflow';
      }
      if (props.isStatus && !props.isStaticColor) {
        const statusMap = {
          zombie: 'complete',
          'released-to-inventory': 'inventory',
          'not-started': 'scheduled',
          fulfilled: 'complete',
          noWorkflow: 'No Workflow',
          approvedAsNoted: 'Approved As Noted',
          Approvedasnoted: 'Approved As Noted',
          forRecordOnly: 'For Record Only',
          reviseAndResubmit: 'Revise And Resubmit',
          'short delivery': 'Short Delivery',
        };
        if (props.shipType === 'm' && props.value === 'mixed') {
          if (every(props.shippingItems, (item) => {
            if (item.masterSummary) {
              return item.masterSummary.delivered === item.masterSummary.total;
            }
          })) {
            return 'Complete';
          }
        }
        if (props.shipType === 's' && props.value === 'in-storage') {
          const isUnderDelivered = props.shippingItems.filter((item) => item.underDeliver && item.underDeliver > 0);
          if (isUnderDelivered.length) {
            return 'Short Delivery';
          }
        }
        // eslint-disable-next-line max-len
        return statusMap[props.value] ? capitalize(statusMap[props.value]) : capitalize(props.value);
      }
      if (props.value === 'qa') return 'QA/QC';
      return capitalize(props.value);
    });

    function getStaticColor(val) {
      if (val) {
        const color = val.replace(/ /g, '').replace(/-/g, '').toLowerCase();
        const statusColors = {
          notstarted: 'has-background-grey-light',
          scheduled: 'mf-lm-background',
          inprogress: 'has-background-primary',
          complete: 'has-background-success',
          paused: 'has-background-orange',
          noworkflow: 'has-background-grey-light',
          unscheduled: 'has-background-orange',
          underdelivered: 'has-background-danger',
          intransit: 'has-background-primary',
          instorage: 'has-background-primary',
          'short delivery': 'has-background-success',
          inactive: 'has-background-grey-light',
          submitted: 'has-background-success',
          qcinspect: 'has-background-info',
          qcfail: 'has-background-danger',
        };
        if (statusColors[color]) return statusColors[color];
      }
      return '';
    }

    const getQcStatusVal = (val) => {
      if (val === 'Qc inspect') return 'QC Inspect';
      if (val === 'Qc fail') return 'QC Fail';
      return '';
    };

    return {
      colorScheme,
      displayValue,
      colorStatus,
      getStaticColor,
      getQcStatusVal,
      isItemNotPurchased,
    };
  },
});
</script>

<style scoped>
.status-circle {
  border-radius: 50%;
  margin: 4px;
}
</style>
