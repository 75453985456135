import _ from 'lodash';
import validateLmv from '@/components/utils/lmv3dotValidation';

const commonFields = [
  {
    title: 'Order Name',
    name: 'field-generic-input',
    sortField: 'name',
    prop: 'name',
    width: '400px',
    sticky: true,
    id: 'name',
    isClickable: true,
    eventType: 'openCard',
    defaultVisible: true,
    alwaysVisible: true,
    searchField: false, // to give a search bar for the field
    searchable: true, // to place any custom field in the header of the column like for below
    bulkActionButtonField: true,
    inputProps: {
      type: 'text',
    },
  },
];

const actionField = {
  title: 'Actions',
  name: 'table-field-actions',
  id: 'action',
  hideFromChooser: true,
  alwaysVisible: true,
  width: '112px',
  sticky: true,
  thAttrs: () => ({ class: 'action-sticky' }),
  tdClass: 'action-sticky',
  searchable: true,
  clearBtn: true,
  permissionModule: 'production-manager',
  inputProps: {
    showEdit: true,
    options(card) {
      const hasPartialQtyShipped = _.isEmpty(
        card.items.filter((item) => item.quantity !== item.serials.available),
      );
      const opts = [
        {
          icon: 'fa fa-qrcode',
          label: 'Print QR',
          event: 'printQr',
        },
        {
          icon: 'fa fa-history',
          label: 'History',
          event: 'activity',
        },
      ];
      if (card.stage !== 'mixed' && hasPartialQtyShipped) {
        opts.unshift({
          label: 'Delete',
          event: 'remove',
          icon: 'icon-removedelete',
        });
      }
      if (card.stage === 'detailing') {
        opts.unshift({
          label: 'Split',
          event: 'split',
          icon: 'icon-split',
        });
      }
      if (validateLmv.validate3dots(card.items)) {
        opts.push({
          icon: 'icon-linked-model',
          label: 'View Model',
          event: 'lmv',
        });
      }
      return opts;
    },
  },
};

const disableUniqIdEdit = (rowData) => {
  if (rowData?.isEditing && rowData?.kitIds?.length) {
    return true;
  }

  return !rowData.isEditing || false;
};
const detailing = [
  ...commonFields,
  {
    title: 'Description',
    name: 'field-generic-input',
    prop: 'financialId',
    width: '152px',
    id: 'financialId',
    sortField: 'financialId',
    isDisabled: false,
    defaultVisible: false,
  },
  {
    title: 'Order ID',
    name: 'field-generic-input',
    prop: 'uniqueOrderId.value',
    width: '152px',
    id: 'uniqueOrderId',
    sortField: 'uniqueOrderId.value',
    isDisabled: false,
    defaultVisible: false,
    inputProps: {
      disableEdit: disableUniqIdEdit,
    },
  },
  {
    title: 'Kit ID',
    sortField: 'customId',
    name: 'field-kit-id',
    prop: 'customId',
    width: '152px',
    id: 'customId',
    isDisabled: true,
    defaultVisible: true,
    alwaysVisible: false,
  }, {
    title: ' ',
    name: 'lmvIcon',
    width: '64px',
    id: 'lmvIcon',
    customHeader: true,
    customSlotHeader: true,
    defaultVisible: true,
    inputProps: {
      icon: 'icon-linked-model',
    },
  }, {
    title: 'Project',
    name: 'field-generic-input',
    prop: 'project.name',
    sortField: 'project',
    searchable: true,
    filterText: 'Projects',
    width: '296px',
    id: 'project',
    isDisabled: true,
    defaultVisible: true,
    alwaysVisible: false,
  }, {
    title: 'Stage',
    name: 'field-stage',
    prop: 'stage',
    sortField: 'stage',
    searchable: false,
    id: 'orderStage',
    width: '152px',
    defaultVisible: false,
    alwaysVisible: false,
  },
  {
    title: 'Status',
    name: 'selectStatus',
    prop: 'status',
    sortField: 'status',
    searchable: true,
    filterText: 'Status',
    width: '152px',
    id: 'status',
    defaultVisible: true,
    isDisabled: true,
    isStatus: true,
    isStaticColor: true,
  },
  {
    title: 'Owner',
    name: 'field-user-select',
    prop: 'manager.owner.user',
    width: '296px',
    sortField: 'manager.owner.user',
    id: 'owners',
    searchable: true,
    filterText: 'Owners',
    defaultVisible: true,
    alwaysVisible: false,
    cardProps: {
      companyId: 'created.by.company._id',
      projectId: 'project._id',
      isEditing: 'isEditing',
    },
  },
  {
    title: 'Company',
    name: 'field-generic-input',
    id: 'company',
    prop: 'owner.company.name',
    width: '296px',
    sortField: 'manager.owner.company',
    defaultVisible: false,
    isDisabled: true,
    iPadVisible: true,
  }, {
    title: 'Location',
    name: 'field-location-select',
    id: 'locations',
    searchable: true,
    filterText: 'Locations',
    prop: 'manager.location',
    width: '296px',
    sortField: 'manager.location',
    defaultVisible: true,
    iPadVisible: true,
    cardProps: {
      companyId: 'created.by.company._id',
      projectId: 'project._id',
      isEditing: 'isEditing',
    },
  },
  {
    name: 'field-lvl-zone',
    isDisabled: false,
    sortField: 'level',
    width: '296px',
    title: 'Level',
    id: 'level',
    prop: 'level',
    searchable: true,
    searchField: 'level',
    searchPlaceHolder: 'Level',
    filterText: 'Level',
    inputProps: {
      options: 'levels',
    },
  },
  {
    name: 'field-lvl-zone',
    isDisabled: false,
    sortField: 'zone',
    width: '296px',
    title: 'Zone',
    id: 'zone',
    prop: 'zone',
    searchField: 'zone',
    searchable: true,
    searchPlaceHolder: 'Zone',
    filterText: 'Zone',
    inputProps: {
      options: 'zones',
    },
  }, {
    name: 'field-generic-input',
    prop: 'totalItems',
    width: '112px',
    sortField: 'totalItems',
    title: 'Total Items',
    id: 'totalItems',
    iPadVisible: true,
    defaultVisible: true,
    isDisabled: true,
  }, {
    title: 'Items (% Done)',
    name: 'field-progress-circle',
    prop: 'manager.stats.itemPerc',
    sortField: 'manager.stats.itemPerc',
    searchable: false,
    width: '64px',
    id: 'itemPerc',
    classRight: 'is-pulled-right',
    isDisabled: true,
    defaultVisible: true,
    alwaysVisible: false,
  },
  {
    title: 'Work Steps (% Done)',
    name: 'field-progress-circle',
    prop: 'manager.stats.runsPerc',
    sortField: 'manager.stats.runsPerc',
    searchable: false,
    width: '64px',
    id: 'runsPerc',
    classRight: 'is-pulled-right',
    isDisabled: true,
    defaultVisible: true,
    alwaysVisible: false,
  }, {
    name: 'plannedHrs',
    prop: 'manager.stats.plannedHrs',
    sortField: 'manager.stats.plannedHrs',
    width: '112px',
    title: 'Planned Hours',
    id: 'plannedHrs',
    iPadVisible: true,
    defaultVisible: true,
    isDisabled: true,
  },
  {
    name: 'actualHrs',
    prop: 'manager.stats.actualHrs',
    sortField: 'manager.stats.actualHrs',
    width: '112px',
    title: 'Actual Hours',
    id: 'actualHrs',
    iPadVisible: true,
    defaultVisible: true,
    isDisabled: true,
  },
  {
    title: 'Coordinate By',
    id: 'coord',
    name: 'field-date',
    defaultVisible: false,
    searchable: true,
    dateFilter: 'coord',
    prop: 'coord',
    sortField: 'dates.coord',
    width: '152px',
    kind: 'dates',
    inputProps: {
      kind: 'coord',
      max: ['detailBy', 'manufactureBy', 'qaBy', 'deliver'],
      disableEdit: true,
    },
  },
  {
    title: 'Detail By',
    sortField: 'dates.detailBy',
    id: 'detailBy',
    name: 'field-date',
    defaultVisible: true,
    searchable: true,
    dateFilter: 'detailBy',
    width: '152px',
    prop: 'detailBy',
    kind: 'dates',
    inputProps: {
      kind: 'detailBy',
      min: 'coord',
      max: ['manufactureBy', 'qaBy', 'deliver'],
      disableEdit: () => false,
    },
  }, {
    title: 'Manufacture By',
    id: 'manufactureBy',
    sortField: 'dates.manufactureBy',
    name: 'field-date',
    defaultVisible: true,
    searchable: true,
    dateFilter: 'manufactureBy',
    prop: 'dates',
    kind: 'manufactureBy',
    width: '152px',
    inputProps: {
      kind: 'manufactureBy',
      min: ['coord', 'detailBy'],
      max: ['qaBy', 'deliver'],
      disableEdit: () => false,
    },
  }, {
    title: 'Onsite',
    sortField: 'dates.deliver',
    id: 'deliver',
    name: 'field-date',
    defaultVisible: true,
    alwaysVisible: false,
    searchable: true,
    dateFilter: 'deliver',
    prop: 'deliver',
    kind: 'dates',
    width: '152px',
    inputProps: {
      kind: 'deliver',
      min: ['qaBy', 'manufactureBy', 'detailBy', 'coord'],
      disableEdit: false,
    },
  },
  {
    name: 'lastModified',
    sortField: 'lastModified.at',
    id: 'modified',
    width: '152px',
    title: 'Modified',
    searchable: true,
    classRight: 'is-pulled-right',
    defaultVisible: true,
    dateFilter: 'modified',
    show: true,
  },
  {
    name: 'field-notes-icon',
    title: 'Notes',
    prop: 'manager.notes',
    defaultVisible: true,
    width: '64px',
    id: 'notes',
    inputProps: {
      isItem: false,
    },
    alwaysVisible: false,
  },
  actionField,
];

const manufacturing = [
  ...commonFields,
  {
    title: 'Description',
    name: 'field-generic-input',
    prop: 'financialId',
    width: '152px',
    id: 'financialId',
    sortField: 'financialId',
    isDisabled: false,
    defaultVisible: false,
  },
  {
    title: 'Order ID',
    name: 'field-generic-input',
    prop: 'uniqueOrderId.value',
    width: '152px',
    id: 'uniqueOrderId',
    sortField: 'uniqueOrderId.value',
    isDisabled: false,
    defaultVisible: false,
    inputProps: {
      disableEdit: disableUniqIdEdit,
    },
  },
  {
    title: 'Kit ID',
    name: 'field-kit-id',
    prop: 'customId',
    width: '152px',
    id: 'customId',
    sortField: 'customId',
    isDisabled: true,
    defaultVisible: true,
  }, {
    title: ' ',
    name: 'lmvIcon',
    width: '64px',
    id: 'lmvIcon',
    customHeader: true,
    customSlotHeader: true,
    defaultVisible: true,
    inputProps: {
      icon: 'icon-linked-model',
    },
  }, {
    title: 'Project',
    name: 'field-generic-input',
    prop: 'project.name',
    sortField: 'project',
    searchable: true,
    filterText: 'Projects',
    width: '296px',
    id: 'project',
    isDisabled: true,
    defaultVisible: true,
    alwaysVisible: false,
  }, {
    title: 'Stage',
    name: 'field-stage',
    prop: 'stage',
    searchable: false,
    sortField: 'stage',
    id: 'orderStage',
    width: '152px',
    defaultVisible: false,
    alwaysVisible: false,
    cardProps: {
      cardType: '__t',
    },
  }, {
    title: 'Status',
    name: 'selectStatus',
    prop: 'status',
    sortField: 'status',
    searchable: true,
    filterText: 'Status',
    width: '152px',
    id: 'status',
    defaultVisible: true,
    isDisabled: true,
    isStatus: true,
    isStaticColor: true,
  },
  {
    title: 'Owner',
    name: 'field-user-select',
    prop: 'manager.owner.user',
    width: '296px',
    sortField: 'manager.owner.user',
    id: 'owners',
    searchable: true,
    filterText: 'Owners',
    defaultVisible: true,
    alwaysVisible: false,
    cardProps: {
      companyId: 'created.by.company._id',
      projectId: 'project._id',
      isEditing: 'isEditing',
    },
  },
  {
    title: 'Company',
    name: 'field-generic-input',
    id: 'company',
    prop: 'owner.company.name',
    // searchable: true,
    // filterField: true,
    width: '296px',
    sortField: 'manager.owner.company',
    defaultVisible: false,
    isDisabled: true,
    iPadVisible: true,
  }, {
    title: 'Location',
    name: 'field-location-select',
    id: 'locations',
    searchable: true,
    filterText: 'Locations',
    prop: 'manager.location',
    width: '296px',
    sortField: 'manager.location',
    defaultVisible: true,
    iPadVisible: true,
    cardProps: {
      companyId: 'created.by.company._id',
      projectId: 'project._id',
      isEditing: 'isEditing',
    },
  }, {
    title: 'Level',
    name: 'field-lvl-zone',
    isDisabled: false,
    sortField: 'level',
    width: '296px',
    id: 'level',
    prop: 'level',
    searchable: true,
    searchField: 'level',
    searchPlaceHolder: 'Level',
    filterText: 'Level',
    inputProps: {
      options: 'levels',
    },
  },
  {
    title: 'Zone',
    name: 'field-lvl-zone',
    isDisabled: false,
    sortField: 'zone',
    width: '296px',
    id: 'zone',
    prop: 'zone',
    searchField: 'zone',
    searchable: true,
    searchPlaceHolder: 'Zone',
    filterText: 'Zone',
    inputProps: {
      options: 'levels',
    },
  }, {
    name: 'field-generic-input',
    prop: 'totalItems',
    width: '112px',
    title: 'Total Items',
    id: 'totalItems',
    sortField: 'totalItems',
    iPadVisible: true,
    defaultVisible: true,
    isDisabled: true,
  }, {
    title: 'Items (%Done)',
    name: 'field-progress-circle',
    prop: 'manager.stats.itemPerc',
    sortField: 'manager.stats.itemPerc',
    searchable: false,
    width: '64px',
    id: 'itemPerc',
    isDisabled: true,
    defaultVisible: true,
    alwaysVisible: false,
  },
  {
    title: 'Work Steps (% Done)',
    name: 'field-progress-circle',
    prop: 'manager.stats.runsPerc',
    sortField: 'manager.stats.runsPerc',
    searchable: false,
    width: '64px',
    id: 'runsPerc',
    isDisabled: true,
    defaultVisible: true,
    alwaysVisible: false,
  },
  {
    name: 'plannedHrs',
    prop: 'manager.stats.plannedHrs',
    sortField: 'manager.stats.plannedHrs',
    width: '112px',
    title: 'Planned Hours',
    id: 'plannedHrs',
    iPadVisible: true,
    defaultVisible: true,
    isDisabled: true,
  },
  {
    name: 'actualHrs',
    prop: 'manager.stats.actualHrs',
    sortField: 'manager.stats.actualHrs',
    width: '112px',
    title: 'Actual Hours',
    id: 'actualHrs',
    iPadVisible: true,
    defaultVisible: true,
    isDisabled: true,
  }, {
    title: 'Coordinate By',
    id: 'coord',
    name: 'field-date',
    defaultVisible: false,
    searchable: true,
    dateFilter: 'coord',
    prop: 'coord',
    sortField: 'dates.coord',
    width: '152px',
    kind: 'dates',
    inputProps: {
      kind: 'coord',
      max: ['detailBy', 'manufactureBy', 'qaBy', 'deliver'],
      disableEdit: true,
    },
  },
  {
    title: 'Detail By',
    sortField: 'dates.detailBy',
    id: 'detailBy',
    name: 'field-date',
    defaultVisible: false,
    searchable: true,
    dateFilter: 'detailBy',
    width: '152px',
    prop: 'detailBy',
    kind: 'dates',
    inputProps: {
      kind: 'detailBy',
      max: ['manufactureBy', 'qaBy', 'deliver'],
      min: 'coord',
      disableEdit: true,
    },
  },
  {
    title: 'Manufacture By',
    id: 'manufactureBy',
    sortField: 'dates.manufactureBy',
    name: 'field-date',
    defaultVisible: true,
    alwaysVisible: false,
    searchable: true,
    dateFilter: 'manufactureBy',
    prop: 'dates',
    kind: 'manufactureBy',
    width: '152px',
    inputProps: {
      kind: 'manufactureBy',
      max: ['qaBy', 'deliver'],
      min: ['detailBy', 'coord'],
      disableEdit: () => false,
    },
  }, {
    title: 'Onsite',
    sortField: 'dates.deliver',
    id: 'deliver',
    name: 'field-date',
    defaultVisible: true,
    alwaysVisible: false,
    searchable: true,
    dateFilter: 'deliver',
    prop: 'deliver',
    kind: 'dates',
    width: '152px',
    inputProps: {
      kind: 'deliver',
      min: ['qaBy', 'manufactureBy', 'detailBy', 'coord'],
      disableEdit: false,
    },
  }, {
    name: 'lastModified',
    sortField: 'lastModified.at',
    id: 'modified',
    width: '152px',
    title: 'Modified',
    searchable: true,
    classRight: 'is-pulled-right',
    defaultVisible: true,
    dateFilter: 'modified',
    show: true,
  },
  {
    name: 'field-notes-icon',
    title: 'Notes',
    prop: 'manager.notes',
    defaultVisible: true,
    width: '64px',
    id: 'notes',
    inputProps: {
      isItem: false,
    },
    alwaysVisible: false,
  },
  actionField,
];

const qa = [
  ...commonFields,
  {
    title: 'Description',
    name: 'field-generic-input',
    prop: 'financialId',
    width: '152px',
    id: 'financialId',
    sortField: 'financialId',
    isDisabled: false,
    defaultVisible: false,
  },
  {
    title: 'Order ID',
    name: 'field-generic-input',
    prop: 'uniqueOrderId.value',
    width: '152px',
    id: 'uniqueOrderId',
    sortField: 'uniqueOrderId.value',
    isDisabled: false,
    defaultVisible: false,
    inputProps: {
      disableEdit: disableUniqIdEdit,
    },
  },
  {
    title: 'Kit ID',
    name: 'field-kit-id',
    prop: 'customId',
    width: '152px',
    id: 'customId',
    sortField: 'customId',
    isDisabled: true,
    defaultVisible: true,
  }, {
    title: ' ',
    name: 'lmvIcon',
    width: '64px',
    id: 'lmvIcon',
    customHeader: true,
    defaultVisible: true,
    customSlotHeader: true,
    inputProps: {
      icon: 'icon-linked-model',
    },
  }, {
    title: 'Project',
    name: 'field-generic-input',
    prop: 'project.name',
    sortField: 'project',
    searchable: true,
    filterText: 'Projects',
    width: '296px',
    id: 'project',
    isDisabled: true,
    defaultVisible: true,
    alwaysVisible: false,
  }, {
    title: 'Stage',
    name: 'field-stage',
    prop: 'stage',
    sortField: 'stage',
    searchable: false,
    id: 'orderStage',
    width: '152px',
    defaultVisible: false,
    alwaysVisible: false,
    cardProps: {
      cardType: '__t',
    },
  }, {
    title: 'Status',
    name: 'selectStatus',
    prop: 'status',
    sortField: 'status',
    searchable: true,
    filterText: 'Status',
    width: '152px',
    id: 'status',
    defaultVisible: true,
    isDisabled: true,
    isStatus: true,
    isStaticColor: true,
  },
  {
    title: 'Owner',
    name: 'field-user-select',
    prop: 'manager.owner.user',
    width: '296px',
    sortField: 'manager.owner.user',
    id: 'owners',
    searchable: true,
    filterText: 'Owners',
    defaultVisible: true,
    alwaysVisible: false,
    cardProps: {
      companyId: 'created.by.company._id',
      projectId: 'project._id',
      isEditing: 'isEditing',
    },
  },
  {
    title: 'Company',
    name: 'field-generic-input',
    id: 'company',
    prop: 'owner.company.name',
    // searchable: true,
    // filterField: true,
    width: '296px',
    sortField: 'manager.owner.company',
    defaultVisible: false,
    isDisabled: true,
    iPadVisible: true,
  }, {
    title: 'Location',
    name: 'field-location-select',
    id: 'locations',
    searchable: true,
    filterText: 'Locations',
    prop: 'manager.location',
    width: '296px',
    sortField: 'manager.location',
    defaultVisible: true,
    iPadVisible: true,
    cardProps: {
      companyId: 'created.by.company._id',
      projectId: 'project._id',
      isEditing: 'isEditing',
    },
  }, {
    title: 'Level',
    name: 'field-lvl-zone',
    isDisabled: false,
    sortField: 'level',
    width: '296px',
    id: 'level',
    prop: 'level',
    searchable: true,
    searchField: 'level',
    searchPlaceHolder: 'Level',
    filterText: 'Level',
    inputProps: {
      options: 'levels',
    },
  },
  {
    title: 'Zone',
    name: 'field-lvl-zone',
    isDisabled: false,
    sortField: 'zone',
    width: '296px',
    id: 'zone',
    prop: 'zone',
    searchField: 'zone',
    searchable: true,
    searchPlaceHolder: 'Zone',
    filterText: 'Zone',
    inputProps: {
      options: 'zones',
    },
  }, {
    name: 'field-generic-input',
    prop: 'totalItems',
    width: '112px',
    title: 'Total Items',
    id: 'totalItems',
    sortField: 'totalItems',
    iPadVisible: true,
    defaultVisible: true,
    isDisabled: true,
  }, {
    name: 'plannedHrs',
    prop: 'manager.stats.plannedHrs',
    sortField: 'manager.stats.plannedHrs',
    width: '112px',
    title: 'Planned Hours',
    id: 'plannedHrs',
    iPadVisible: true,
    defaultVisible: true,
    isDisabled: true,
  },
  {
    name: 'actualHrs',
    prop: 'manager.stats.actualHrs',
    sortField: 'manager.stats.actualHrs',
    width: '112px',
    title: 'Actual Hours',
    id: 'actualHrs',
    iPadVisible: true,
    defaultVisible: true,
    isDisabled: true,
  }, {
    title: 'Coordinate By',
    id: 'coord',
    name: 'field-date',
    defaultVisible: false,
    searchable: true,
    dateFilter: 'coord',
    prop: 'coord',
    sortField: 'dates.coord',
    width: '152px',
    kind: 'dates',
    inputProps: {
      kind: 'coord',
      max: ['detailBy', 'manufactureBy', 'qaBy', 'deliver'],
      disableEdit: true,
    },
  },
  {
    title: 'Detail By',
    sortField: 'dates.detailBy',
    id: 'detailBy',
    name: 'field-date',
    defaultVisible: false,
    searchable: true,
    dateFilter: 'detailBy',
    width: '152px',
    prop: 'detailBy',
    kind: 'dates',
    inputProps: {
      kind: 'detailBy',
      max: ['manufactureBy', 'qaBy', 'deliver'],
      min: 'coord',
      disableEdit: true,
    },
  },
  {
    title: 'Manufacture By',
    id: 'manufactureBy',
    name: 'field-date',
    defaultVisible: false,
    alwaysVisible: false,
    searchable: true,
    sortField: 'dates.manufactureBy',
    dateFilter: 'detailBy',
    prop: 'manufactureBy',
    kind: 'manufactureBy',
    width: '152px',
    inputProps: {
      kind: 'manufactureBy',
      max: ['manufactureBy', 'qaBy', 'deliver'],
      min: ['detailBy', 'coord'],
      disableEdit: true,
    },
  },
  {
    title: 'QA/QC By',
    name: 'field-date',
    searchable: true,
    dateFilter: 'qaBy',
    defaultVisible: true,
    alwaysVisible: false,
    sortField: 'dates.qaBy',
    prop: 'dates',
    width: '152px',
    id: 'qaBy',
    inputProps: {
      kind: 'qaBy',
      max: ['qaBy', 'deliver'],
      min: ['manufactureBy', 'detailBy', 'coord'],
      disableEdit: () => false,
    },
  }, {
    title: 'Onsite',
    sortField: 'dates.deliver',
    id: 'deliver',
    name: 'field-date',
    defaultVisible: true,
    alwaysVisible: false,
    searchable: true,
    dateFilter: 'deliver',
    prop: 'deliver',
    kind: 'dates',
    width: '152px',
    inputProps: {
      kind: 'deliver',
      min: ['qaBy', 'manufactureBy', 'detailBy', 'coord'],
      disableEdit: false,
    },
  }, {
    name: 'lastModified',
    sortField: 'lastModified.at',
    id: 'modified',
    width: '152px',
    title: 'Modified',
    searchable: true,
    classRight: 'is-pulled-right',
    defaultVisible: true,
    dateFilter: 'modified',
    show: true,
  },
  {
    name: 'field-notes-icon',
    title: 'Notes',
    prop: 'manager.notes',
    defaultVisible: true,
    width: '64px',
    id: 'notes',
    inputProps: {
      isItem: false,
    },
    alwaysVisible: false,
  },
  actionField,
];

export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  isDetailed: true,
  stickeyHeaders: true,
  rearrangeCols: false,
  detailRowComponent: 'mf-detail-row',
  permissionModule: 'production-manager',
  detailing,
  manufacturing,
  qa,
  gutterOpts: {
    buttons: [{
      event: 'columnChooser',
      icon: 'icon-tablecolumns',
    }],
  },
  toolBar: {
    bulkActions: (stage) => {
      const opts = [];
      if (stage === 'detailing') {
        opts.push(
          // {
          //   name: 'Move Orders To Manufacturing',
          //   event: 'moveForward',
          //   label: 'Move Forward',
          // },
          {
            name: 'Move Orders To Manufacturing',
            event: 'moveOrdersForward',
            label: 'Move Orders',
            nextStage: 'manufacturing'
          },
          {
            name: 'Move Orders Back To Coordination',
            event: 'moveBackward',
            label: 'Move Orders Back',
          },
          {
            name: 'Combine Orders (Limit 2)',
            event: 'combine',
            label: 'Combine Orders',
            refreshOnCancel: true,
          },
          {
            name: 'Print QR Labels',
            event: 'bulkQrPrint',
            label: 'Print QR',
            refreshOnCancel: true,
          },
          {
            name: 'Edit Description',
            event: 'editOrderIds',
            label: 'Edit Order IDs',
          },
          {
            name: 'Delete Orders',
            event: 'removeOrders',
            label: 'Delete',
          },
        );
      }
      if (stage === 'manufacturing') {
        opts.push(
          // {
          //   name: 'Move Orders To QA',
          //   event: 'moveForward',
          //   label: 'Move Forward',
          //   refreshOnCancel: true,
          // },
          {
            name: 'Move Orders To QA',
            event: 'moveOrdersForward',
            label: 'Move Orders',
            refreshOnCancel: true,
          },
          {
            name: 'Move Orders Back To Detailing',
            event: 'moveBackward',
            label: 'Move Orders Back',
          },
          {
            name: 'Print QR Labels',
            event: 'bulkQrPrint',
            label: 'Print QR',
            refreshOnCancel: true,
          },
          {
            name: 'Edit Work Step',
            event: 'editWorkStep',
            label: 'Edit Workstep',
          },
          {
            name: 'Edit Description',
            event: 'editOrderIds',
            label: 'Edit Order IDs',
          },
        );
      }
      if (stage === 'qa') {
        opts.push(
          {
            name: 'Move Orders Back To Manufacturing',
            event: 'moveBackward',
            label: 'Move Orders Back',
          },
          {
            name: 'Print QR Labels',
            event: 'bulkQrPrint',
            label: 'Print QR',
            refreshOnCancel: true,
          },
          {
            name: 'Edit Description',
            event: 'editOrderIds',
            label: 'Edit Order IDs',
          },
        );
      }
      if (['manufacturing', 'qa'].includes(stage)) {
        opts.push({
          name: 'Move Orders to Inventory',
          event: 'moveToInventory',
          label: 'Move to Inventory',
        });
        opts.push({
          name: 'Create Shipping Order',
          event: 'shipOrders',
          label: 'Create Shipping Order',
        });
        opts.push({
          name: 'Create Aggregate Shipping Order',
          event: 'shipMulti',
          label: 'Create Aggregate Shipping Order',
          refreshOnCancel: true,
        });
        opts.push({
          name: 'Move Orders to Kit Location',
          event: 'moveToKitLocation',
          label: 'Move to Kit Location',
        });
        // opts.push({
        //   name: 'Remove Inventory', event: 'removeInventory', label: 'Remove Inventory',
        // });
        opts.push({
          name: 'Delete Orders',
          event: 'removeOrders',
          label: 'Delete',
        });
      }
      return opts;
    },
    hideSearchBar: false,
    buttons: (stage) => {
      const options = [];
      if (stage !== 'qa') {
        options.push({
          event: 'addNew',
          icon: 'icon-addnew',
          tooltip: 'Add',
          isLine: true,
        });
      }
      if (stage === 'detailing') {
        options.push({
          event: 'excelImport',
          showOptionsForStage: 'detailing',
          options: [{
            label: 'Import Production Orders',
            type: 'importPrefab',
          }, {
            label: 'Import Production Order Items',
            type: 'importItems',
          }],
          icon: 'icon-importexcel',
          tooltip: 'Import From Excel',
        });
      }
      return options;
    },
  },
  fields: [
    ...commonFields,
    {
      title: 'Actions',
      name: 'table-field-actions',
      id: 'action',
      hideFromChooser: true,
      alwaysVisible: false,
      width: '112px',
      searchable: true,
      clearBtn: true,
      sticky: true,
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      inputProps: {
        showEdit: true,
        options(card) {
          const hasPartialQtyShipped = _.isEmpty(
            card.items.filter((item) => item.quantity !== item.serials.available),
          );
          const opts = [
            {
              icon: 'fa fa-qrcode',
              label: 'Print QR',
              event: 'printQr',
            },
            {
              icon: 'fa fa-history',
              label: 'History',
              event: 'activity',
            },
          ];
          if (card.stage !== 'mixed' && hasPartialQtyShipped) {
            opts.unshift({
              label: 'Delete',
              event: 'remove',
              icon: 'icon-removedelete',
            });
          }
          if (card.stage === 'detailing') {
            opts.unshift({
              label: 'Split',
              event: 'split',
              icon: 'icon-split',
            });
          }
          if (validateLmv.validate3dots(card.items)) {
            opts.push({
              icon: 'icon-linked-model',
              label: 'View Model',
              event: 'lmv',
            });
          }
          return opts;
        },
      },
    },
  ],
};
